.home {
    position: fixed;
    top:0;
    left: 251px;
    width: calc(100% - 251px);
    height: 100%;
}

.home video {
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height:100%;
    object-fit: cover;
}