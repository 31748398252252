.leftPanel {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    border-right: 1px solid black;
    position: relative;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 700px) {
    .leftPanel {
        min-width: 100%;
        max-width: 100%;
    }
}

.logo {
    height: 250px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    height: 50%;
}

.socials {
    border-top: 1px solid black;
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: white;
}


.socials .left {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
}

.socials .right {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.socials a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0
}

.socials a img{
    height: 40%;
    margin: 5px;
}