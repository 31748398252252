.latest {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 1rem;
}

.latest .show {
    margin-bottom: 2%;
    border: 1px solid black;
    padding: 10px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

@media only screen and (min-width: 1440px) {
    .latest .show {
        flex: 0 20%;
    }
}

@media only screen and (max-width: 1000px) {
    .latest .show {
        flex: 0 45%;
    }
}

.latest .show:hover {
    background-color: black;
    color: white;
}

.latest .show .top{
    display: flex;
    padding-bottom: 10px;
}

.latest .show .image{
    min-width: 75px;
    min-height: 75px;
    height: 75px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


.latest .show:hover .image {
    background-image: url("./play.svg") !important;
    filter: invert(100%);
    background-size: 40%;
}


.latest .show .right{
    text-align: right;
    flex-grow: 1;
    margin-left: 10px;
}

.latest .show .right .date {
    white-space: nowrap;
}

.latest .show .right .tags{
    font-size: 14px;
    line-height: 1.3em;
    max-height: 3.9em;
    overflow: hidden;
}

.latest .show .desc{
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading {
    background-image: url("./loading.gif") !important;
    background-size: 15%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
}